import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { MultiSelectModule } from 'primeng/multiselect';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/internal/operators/first';
import { Subscription } from 'rxjs/internal/Subscription';
import { EquipmentType } from '../../../models/EquipmentType';
import { Tag } from '../../../models/Tag';
import { TagGridView } from '../../../models/TagGridView';
import { CalendarModule } from 'primeng/calendar';
import { DatatypeService } from '../../../services/datatype/datatype.service';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { EquipmenttypeService } from '../../../services/equipmenttype/equipmenttype.service';
import { PlcService } from '../../../services/plc/plc.service';
import { SubgroupService } from '../../../services/subgroup/subgroup.service';
import { SupplierService } from '../../../services/supplier/supplier.service';
import { SystemService } from '../../../services/system/system.service';
import { SystemnameService } from '../../../services/systemname/systemname.service';
import { TagService } from '../../../services/tag/tag.service';
import { UnitofmeasureService } from '../../../services/unitofmeasure/unitofmeasure.service';
import { VesselService } from '../../../services/vessel/vessel.service';
import { SelectItem } from 'primeng/api/selectitem';
import { FilterMatchMode } from 'primeng/api';


@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css']
})
export class TagListComponent {

  tags: TagGridView[] = [];
  totalRecords: number = 0;

  showLoading: boolean = false;
  latestLazyLoadEvent?: LazyLoadEvent;
  tagSubscription?: Subscription;
  updatesSubscription?: Subscription;

  dataTypes: string[] = [];
  equipments: string[] = [];
  equipmentTypes: string[] = [];
  plcs: string[] = [];
  subGroups: string[] = [];
  suppliers: string[] = [];
  systems: string[] = [];
  systemNames: string[] = [];
  unitOfMeasures: string[] = [];
  vessels: string[] = [];
  matchModeOptions: SelectItem[] = [];

  selectedTags: Tag[] = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tagService: TagService,
    private dataTypeService: DatatypeService,
    private equipmentService: EquipmentService,
    private equipmentTypeService: EquipmenttypeService,
    private plcService: PlcService,
    private subGroupService: SubgroupService,
    private supplierService: SupplierService,
    private systemService: SystemService,
    private systemNameService: SystemnameService,
    private unitOfMeasureService: UnitofmeasureService,
    private vesselService: VesselService
  ) { }



  ngOnInit(): void {
    /*    this.subscribeToTagUpdates();*/

    this.matchModeOptions = [
      {
        label: 'Date is',
        value: FilterMatchMode.DATE_IS
      },
      {
        label: 'Date is not',
        value: FilterMatchMode.DATE_IS_NOT
      },
      {
        label: 'Date is after',
        value: FilterMatchMode.DATE_AFTER
      },
      {
        label: 'Date is before',
        value: FilterMatchMode.DATE_BEFORE
      },
    ];


    this.dataTypeService.getAll().pipe(first()).subscribe(x => {
      this.dataTypes = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.equipmentService.getAll().pipe(first()).subscribe(x => {
      this.equipments = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.equipmentTypeService.getAll().pipe(first()).subscribe(x => {
      this.equipmentTypes = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.plcService.getAll().pipe(first()).subscribe(x => {
      this.plcs = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.subGroupService.getAll().pipe(first()).subscribe(x => {
      this.subGroups = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.supplierService.getAll().pipe(first()).subscribe(x => {
      this.suppliers = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.systemService.getAll().pipe(first()).subscribe(x => {
      this.systems = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.systemNameService.getAll().pipe(first()).subscribe(x => {
      this.systemNames = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.unitOfMeasureService.getAll().pipe(first()).subscribe(x => {
      this.unitOfMeasures = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
    this.vesselService.getAll().pipe(first()).subscribe(x => {
      this.vessels = x.map(y => y.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));;
    });
  }

  ngOnDestroy() {
    this.tagSubscription?.unsubscribe();
    this.updatesSubscription?.unsubscribe();
  }


  loadTags(lazyEvent: LazyLoadEvent) {
    this.showLoading = true;
    this.latestLazyLoadEvent = lazyEvent;
    this.tagSubscription = this.tagService.getTagsLazy(lazyEvent).subscribe(x => {
        this.tags = x.records;
        this.totalRecords = x.totalRecords;
        this.showLoading = false;
    });
  }

  edit(tag: Tag) {
    this.router.navigateByUrl("/tags/" + tag.id);
  }

  exportSelection() {
    console.log("tag-list.component.ts:exportSelection()");
    var tagIds = this.selectedTags.map(x => x.id);

    this.tagService.exportSelection(tagIds).pipe(first()).subscribe(x => {
      var newBlob = new Blob([x], { type: "application/ms-excel" });

      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = "test.xlsx";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  deleteSelection() {
    var tagIds = this.selectedTags.map(x => x.id);
    this.tagService.deleteSelection(tagIds).pipe(first()).subscribe(x => {
      for (var i = 0; i < this.selectedTags.length; i++) {
        var id = this.selectedTags[i].id;
        this.tags.splice(this.tags.findIndex(item => item.id === id), 1);
      }
      this.selectedTags = [];     
    });
  }

}
