import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { TagListComponent } from './components/tag/tag-list/tag-list.component';
import { AdminListComponent } from './components/admin/admin-list/admin-list.component';
import { DatatypeListComponent } from './components/datatype/datatype-list/datatype-list.component';
import { DatatypeEditComponent } from './components/datatype/datatype-edit/datatype-edit.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { WinAuthInterceptor } from './models/WinAuthInterceptor';
import { EquipmentEditComponent } from './components/equipment/equipment-edit/equipment-edit.component';
import { EquipmentListComponent } from './components/equipment/equipment-list/equipment-list.component';
import { EquipmenttypeEditComponent } from './components/equipmenttype/equipmenttype-edit/equipmenttype-edit.component';
import { EquipmenttypeListComponent } from './components/equipmenttype/equipmenttype-list/equipmenttype-list.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { PlcEditComponent } from './components/plc/plc-edit/plc-edit.component';
import { PlcListComponent } from './components/plc/plc-list/plc-list.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SubgroupEditComponent } from './components/subgroup/subgroup-edit/subgroup-edit.component';
import { SubgroupListComponent } from './components/subgroup/subgroup-list/subgroup-list.component';
import { SupplierEditComponent } from './components/supplier/supplier-edit/supplier-edit.component';
import { SupplierListComponent } from './components/supplier/supplier-list/supplier-list.component';
import { SystemEditComponent } from './components/system/system-edit/system-edit.component';
import { SystemListComponent } from './components/system/system-list/system-list.component';
import { ToastModule } from 'primeng/toast';
import { UnitofmeasureEditComponent } from './components/unitofmeasure/unitofmeasure-edit/unitofmeasure-edit.component';
import { UnitofmeasureListComponent } from './components/unitofmeasure/unitofmeasure-list/unitofmeasure-list.component';
import { VesselEditComponent } from './components/vessel/vessel-edit/vessel-edit.component';
import { VesselListComponent } from './components/vessel/vessel-list/vessel-list.component';
import { SystemnameListComponent } from './components/systemname/systemname-list/systemname-list.component';
import { SystemnameEditComponent } from './components/systemname/systemname-edit/systemname-edit.component';
import { TagExcelUploadComponent } from './components/tag/tag-excel-upload/tag-excel-upload.component';
import { TagEditComponent } from './components/tag/tag-edit/tag-edit.component';
import { MainlayoutComponent } from './components/layouts/mainlayout/mainlayout.component';
import { environment } from '../environments/environment';


import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { TagGenIdsComponent } from './components/tag/tag-gen-ids/tag-gen-ids.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalClientId,
      authority: "https://login.microsoftonline.com/subsea7.onmicrosoft.com",
      redirectUri: '/logged-in',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);
  protectedResourceMap.set(environment.apiEndpoint, [
    `${environment.msalApiId}/user_impersonation`,
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', `${environment.msalApiId}/user_impersonation`],
    },
    loginFailedRoute: '/login-failed',
  };
}


const appRoutes: Routes = [
  { path: '', component: TagListComponent, canActivate: [MsalGuard] },

  { path: 'logged-in', redirectTo: 'tags' },

  { path: 'tags', component: TagListComponent, canActivate: [MsalGuard] },
  { path: 'admin', component: AdminListComponent, canActivate: [MsalGuard] },

  { path: 'admin/datatype', component: DatatypeListComponent, canActivate: [MsalGuard] },
  { path: 'admin/datatype/create', component: DatatypeEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/datatype/:id', component: DatatypeEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/equipment', component: EquipmentListComponent, canActivate: [MsalGuard] },
  { path: 'admin/equipment/create', component: EquipmentEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/equipment/:id', component: EquipmentEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/equipmenttype', component: EquipmenttypeListComponent, canActivate: [MsalGuard] },
  { path: 'admin/equipmenttype/create', component: EquipmenttypeEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/equipmenttype/:id', component: EquipmenttypeEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/plc', component: PlcListComponent, canActivate: [MsalGuard] },
  { path: 'admin/plc/create', component: PlcEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/plc/:id', component: PlcEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/subgroup', component: SubgroupListComponent, canActivate: [MsalGuard] },
  { path: 'admin/subgroup/create', component: SubgroupEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/subgroup/:id', component: SubgroupEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/supplier', component: SupplierListComponent, canActivate: [MsalGuard] },
  { path: 'admin/supplier/create', component: SupplierEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/supplier/:id', component: SupplierEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/system', component: SystemListComponent, canActivate: [MsalGuard] },
  { path: 'admin/system/create', component: SystemEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/system/:id', component: SystemEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/systemname', component: SystemnameListComponent, canActivate: [MsalGuard] },
  { path: 'admin/systemname/create', component: SystemnameEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/systemname/:id', component: SystemnameEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/unitofmeasure', component: UnitofmeasureListComponent, canActivate: [MsalGuard] },
  { path: 'admin/unitofmeasure/create', component: UnitofmeasureEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/unitofmeasure/:id', component: UnitofmeasureEditComponent, canActivate: [MsalGuard] },

  { path: 'admin/vessel', component: VesselListComponent, canActivate: [MsalGuard] },
  { path: 'admin/vessel/create', component: VesselEditComponent, canActivate: [MsalGuard] },
  { path: 'admin/vessel/:id', component: VesselEditComponent, canActivate: [MsalGuard] },


  { path: 'tags/genids', component: TagGenIdsComponent, canActivate: [MsalGuard] },
  { path: 'tags/upload', component: TagExcelUploadComponent, canActivate: [MsalGuard] },
  { path: 'tags/create', component: TagEditComponent, canActivate: [MsalGuard] },
  { path: 'tags/:id', component: TagEditComponent, canActivate: [MsalGuard] },

];




@NgModule({
  declarations: [
    AppComponent,
    TagListComponent,
    AdminListComponent,
    DatatypeListComponent,
    DatatypeEditComponent,
    EquipmentEditComponent,
    EquipmentListComponent,
    EquipmenttypeEditComponent,
    EquipmenttypeListComponent,
    PlcEditComponent,
    PlcListComponent,
    SubgroupEditComponent,
    SubgroupListComponent,
    SupplierEditComponent,
    SupplierListComponent,
    SystemEditComponent,
    SystemListComponent,
    UnitofmeasureEditComponent,
    UnitofmeasureListComponent,
    VesselEditComponent,
    VesselListComponent,
    SystemnameListComponent,
    SystemnameEditComponent,
    TagExcelUploadComponent,
    TagEditComponent,
    MainlayoutComponent,
    TagGenIdsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    DropdownModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    TableModule,
    ToastModule
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WinAuthInterceptor,
      multi: true
    },    
    {
      // remove this when working on local machine, required for deployment
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService, ConfirmationService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
