import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';
import { DataType } from '../../../models/DataType';
import { Equipment } from '../../../models/Equipment';
import { EquipmentType } from '../../../models/EquipmentType';
import { PLC } from '../../../models/PLC';
import { SubGroup } from '../../../models/SubGroup';
import { Supplier } from '../../../models/Supplier';
import { System } from '../../../models/System';
import { SystemName } from '../../../models/SystemName';
import { Tag } from '../../../models/Tag';
import { UnitOfMeasure } from '../../../models/UnitOfMeasure';
import { Vessel } from '../../../models/Vessel';
import { DatatypeService } from '../../../services/datatype/datatype.service';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { EquipmenttypeService } from '../../../services/equipmenttype/equipmenttype.service';
import { PlcService } from '../../../services/plc/plc.service';
import { SubgroupService } from '../../../services/subgroup/subgroup.service';
import { SupplierService } from '../../../services/supplier/supplier.service';
import { SystemService } from '../../../services/system/system.service';
import { SystemnameService } from '../../../services/systemname/systemname.service';
import { TagService } from '../../../services/tag/tag.service';
import { UnitofmeasureService } from '../../../services/unitofmeasure/unitofmeasure.service';
import { VesselService } from '../../../services/vessel/vessel.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.css']
})
export class TagEditComponent {

  @Input() id?: number;
  doCreate: Boolean = false;
  generatingTsIds: Boolean = false;

  tag$ = new Observable<Tag>();

  dataTypes: DataType[] = [];
  equipments: Equipment[] = [];
  equipmentTypes: EquipmentType[] = [];
  plcs: PLC[] = [];
  subGroups: SubGroup[] = [];
  suppliers: Supplier[] = [];
  systems: System[] = [];
  systemNames: SystemName[] = [];
  unitOfMeasures: UnitOfMeasure[] = [];
  vessels: Vessel[] = [];

  testDate: Date = new Date();

  isSubmitDisabled: boolean = false;




  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private tagService: TagService,
    private dataTypeService: DatatypeService,
    private equipmentService: EquipmentService,
    private equipmentTypeService: EquipmenttypeService,
    private plcService: PlcService,
    private subGroupService: SubgroupService,
    private supplierService: SupplierService,
    private systemService: SystemService,
    private systemNameService: SystemnameService,
    private unitOfMeasureService: UnitofmeasureService,
    private vesselService: VesselService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id === undefined) {
        // create a new user
        this.tag$ = this.tagService.getNew();
        this.doCreate = true;
      }
      else {
        // edit an existing user
        this.tag$ = this.tagService.get(this.id);
        this.doCreate = false;
      }
    });

    this.dataTypeService.getAllVMs().pipe(first()).subscribe(x => {
      this.dataTypes = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.equipmentService.getAllVMs().pipe(first()).subscribe(x => {
      this.equipments = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.equipmentTypeService.getAllVMs().pipe(first()).subscribe(x => {
      this.equipmentTypes = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.plcService.getAllVMs().pipe(first()).subscribe(x => {
      this.plcs = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.subGroupService.getAllVMs().pipe(first()).subscribe(x => {
      this.subGroups = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.supplierService.getAllVMs().pipe(first()).subscribe(x => {
      this.suppliers = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.systemService.getAllVMs().pipe(first()).subscribe(x => {
      this.systems = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.systemNameService.getAllVMs().pipe(first()).subscribe(x => {
      this.systemNames = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.unitOfMeasureService.getAllVMs().pipe(first()).subscribe(x => {
      this.unitOfMeasures = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });
    this.vesselService.getAllVMs().pipe(first()).subscribe(x => {
      this.vessels = x.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
    });

  }

  onSubmit(tag: Tag) {

    if (tag.supplier && tag.supplier.id == null) {
      tag.supplier = { id: 0, name: '' };
    }

    var clockBytesFlag = false;
    if (tag.equipmentType && tag.equipmentType.name.toLowerCase() == "clock_byte") {
      clockBytesFlag = true;
    }

    this.isSubmitDisabled = true;

    var errorFlag = false;

    if (tag.vessel == null || tag.vessel.id == null) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Vessel not selected' });
    }
    if (tag.equipmentType == null || tag.equipmentType.id == null) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Equipment type not selected' });
    }
    if (tag.address == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Address not entered' });
    }
    if (tag.dataType == null || tag.dataType.id == null) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Data type not selected' });
    }
    if (tag.description == '') {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Description not entered' });
    }
    if (tag.plc == null || tag.plc.id == null) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'PLC not selected' });
    }
    if (!tag.samplingRate) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Sampling rate not entered' });
    }
    if ((tag.supplier == null || tag.supplier.id == null) && !clockBytesFlag) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Supplier not selected' });
    }
    if (tag.supplierTag == '' && !clockBytesFlag) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'Supplier tag not entered' });
    }
    if ((tag.systemName == null || tag.systemName.id == null) && !clockBytesFlag) {
      errorFlag = true;
      this.messageService.add({ severity: 'error', summary: 'Required Field', detail: 'System name not selected' });
    }

    if (tag.streamID == '') {
      tag.streamID = '00000000-0000-0000-0000-000000000000';
    }
    if (tag.timeSeriesID == '') {
      tag.timeSeriesID = '00000000-0000-0000-0000-000000000000';
    }
    if (!tag.sequence) {
      tag.sequence = 0;
    }

    if (errorFlag) {
      this.isSubmitDisabled = false;
    }
    else {

      if (tag.id == 0) {
        this.tagService.create(tag, clockBytesFlag).pipe(first()).subscribe(
          result => {
            this.router.navigateByUrl("/");
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'An error has occurred', detail: error.error });
            this.isSubmitDisabled = false;
          });
      }
      else {
        this.tagService.update(tag, clockBytesFlag).pipe(first()).subscribe(
          result => {
            this.router.navigateByUrl("/");
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'An error has occurred', detail: error.error });
            this.isSubmitDisabled = false;
          });
      }
    }
  }


  onGenTsIds(tag: Tag) {
    this.generatingTsIds = true;
    this.tagService.generateTsIds(tag).subscribe(t => {
      this.generatingTsIds = false;
      if (t.error) {
        this.messageService.add({ severity: 'error', summary: 'And error occured', detail: t.error });
        return;
      }
      tag.streamID = t.streamID;
      tag.timeSeriesID = t.timeSeriesID;
    }, error => {
      this.generatingTsIds = false;
      this.messageService.add({ severity: 'error', summary: 'And error occured', detail: error.error });
    });
  }


  onConfirmGenTsIds(tag: Tag) {

    this.confirmationService.confirm({
      message: 'Are you sure that you want to generate time series and stream IDs for this tag?',
      icon: 'pi pi-question-circle',
      accept: () => {
        this.onGenTsIds(tag)
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  onConfirmDelete(tag: Tag) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this tag?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(tag.id);
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  onDelete(id: number) {
    this.tagService.delete(id).pipe(first()).subscribe(x => {
      this.router.navigateByUrl("/");
    });
  }

  equipment_onChange(event: any) {

    var tag = null;
    this.tag$.pipe(first()).subscribe(x => {
      if (x.equipment && x.equipment.id) {
        var equipment_id = x.equipment.id;

        var system_id = parseInt(equipment_id.toString().substring(0, 1));
        var _system = this.systems.find(s => s.id == system_id);
        if (_system) {
          x.system = <System>_system;
        }

        var subGroup_id = parseInt(equipment_id.toString().substring(0, 2));
        var _subGroup = this.subGroups.find(s => s.id == subGroup_id);
        if (_subGroup) {
          x.subGroup = <SubGroup>_subGroup;
        }
      }           
    });

  }
}
